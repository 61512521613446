import React from 'react'
import Badge from 'react-bootstrap/Badge'
import BaseCard from '../BaseCard/BaseCard.js'
import Octicon, {GitCommit} from '@githubprimer/octicons-react'

class ContributorCard extends React.Component {
  contributions(d) {
    return `${d.contributions} contributions`
  }

  buildBadge(i) {
    if (i > 0) {
      return null
    }
    return <Badge variant="success">#1 Contributor</Badge>
  }

  render() {
    const d = this.props.data
    const i = this.props.index
    return (
      <BaseCard imgUrl={d.avatar_url}
                user={d.login}
                onClick={this.props.onClick}
                iconRender={() => (
                  <Octicon icon={GitCommit}
                           verticalAlign="middle"
                           size="medium" />
                )}
                bodyRender={() => (
                  this.contributions(d)
                )}
                footRender={() => (
                  this.buildBadge(i)
                )} />
    )
  }
}
export default ContributorCard
