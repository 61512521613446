import React from 'react'
import BaseCard from '../BaseCard/BaseCard.js'
import LabelsList from '../LabelsList.js'
import Octicon, {IssueOpened} from '@githubprimer/octicons-react'
import * as moment from 'moment'

class IssueCard extends React.Component {
  render() {
    const p = this.props
    return (
      <BaseCard imgUrl={p.data.user.avatar_url}
                user={p.data.user.login}
                onClick={p.onClick}
                iconRender={() => (
                  <Octicon icon={IssueOpened}
                           verticalAlign="middle"
                           size="medium" />
                )}
                bodyRender={() => (
                  <>
                    <div className="mb-1">{p.data.title}</div>
                    <LabelsList labels={this.props.data.labels} />
                  </>
                )}
                footRender={() => (
                  <>{moment(p.data.created_at).fromNow()}</>
                )} />
    )
  }
}
export default IssueCard
