import React from 'react'
import ContributorCard from './ContributorCard.js'
import Helpers from '../../utils/Helpers.js'

class ContributorList extends React.Component {
  buildContributorCards() {
    const listItems = this.props.data.map((d,i) =>
      <ContributorCard key={d.node_id}
                       data={d}
                       index={i}
                       onClick={() => Helpers.openURL(d.html_url)} />
    )
    return listItems
  }

  render() {
    return (
      <div className="w-100 d-flex flex-wrap justify-content-center">
        {this.buildContributorCards()}
      </div>
    )
  }
}
export default ContributorList
