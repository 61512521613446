import React from 'react'
import Badge from 'react-bootstrap/Badge'
import Octicon, {Repo,RepoPush} from '@githubprimer/octicons-react'
import * as moment from 'moment'

class RepoCardBody extends React.Component {
  render() {
    const repo = this.props.repo
    return (
      <>
        <div className="flex-fill">
          <h5>
            <Octicon icon={Repo}
                     verticalAlign="middle"
                     className="mr-1" />
            {repo.name}
          </h5>
          <div>by: {repo.owner.login}</div>
        </div>
        <div className="d-none d-lg-flex flex-column">
          <div>
          <Badge variant="dark" className="float-right">
            {repo.language}
          </Badge>
          </div>
          <div>
            <Octicon icon={RepoPush}
                     verticalAlign="middle"
                     className="mr-1" />
            <small>{moment(repo.pushed_at).fromNow()}</small>
          </div>
        </div>
      </>
    )
  }
}
export default RepoCardBody
