class Helpers {

  shortNum(num) {
    const syms = ["", "K", "M", "B"]
    const tier = Math.log10(num) / 3 | 0
    if (tier === 0) return num
    const suffix = syms[tier]
    const scale = Math.pow(10, tier * 3)
    const scaled = num / scale
    return scaled.toFixed(1) + suffix
  }

  static scrollToTop() {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }

  static openURL(url) {
    window.open(url, "_blank")
  }

}

export default Helpers
