import React from 'react'
import EventCard from './EventCard/EventCard.js'
import Helpers from '../../utils/Helpers.js'

class EventList extends React.Component {
  eventClicked(d) {
    const url = `https://github.com/${d.actor.login}`
    Helpers.openURL(url)
  }

  buildEventCards() {
    const listItems = this.props.data.map((d,i) =>
      <EventCard key={`${d.id}-${i}`}
                 data={d}
                 onClick={() => this.eventClicked(d)} />
    )
    return listItems
  }

  render() {
    return (
      <div className="w-100 d-flex flex-wrap justify-content-center">
        {this.buildEventCards()}
      </div>
    )
  }
}
export default EventList
