import React from 'react'
import LoadingBox from '../LoadingBox.js'
import Header from './Header.js'
import RepoList from './RepoList.js'
import './MainContent.css'

class MainContent extends React.Component {
  render() {
    return (
      <div className="content-box d-flex flex-wrap justify-content-center">
        <LoadingBox isOn={this.props.isLoading} />
        <Header featureClick={this.props.onClick}
                route={this.props.route} />
        <RepoList repos={this.props.repos}
                  onClick={this.props.onClick} />
      </div>
    )
  }
}

export default MainContent
