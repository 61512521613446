import React from 'react'
import Image from 'react-bootstrap/Image'
import Media from 'react-bootstrap/Media'

class BaseCardBody extends React.Component {
  render() {
    const p = this.props
    return (
      <Media className="p-2">
        <Image src={p.imgUrl}
               className="avatar-img"
               roundedCircle={true} />
        <Media.Body className="mx-4 d-flex justify-content-between">
          <div className="flex-fill text-muted">
            {p.bodyRender()}
          </div>
          <div className="ml-4 d-none d-lg-flex">
            {p.iconRender()}
          </div>
        </Media.Body>
      </Media>
    )
  }
}
export default BaseCardBody
