import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Octicon from '@githubprimer/octicons-react'

class NavLinks extends React.Component {
  buildLinks() {
    return this.props.linkMap.map((d) =>
      <Nav.Link className="rounded-right bg-dark d-flex flex-column"
                eventKey={d.name} key={d.name}>
                <div className="w-100"><small>{d.name}</small></div>
                <div className="w-100">
                  <Octicon icon={d.icon} />
                </div>
      </Nav.Link>
    )
  }

  render() {
    return (
      <>{this.buildLinks()}</>
    )
  }
}
export default NavLinks
