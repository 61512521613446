import React from 'react'
import Modal from 'react-bootstrap/Modal'
import RepoModalTitle from './RepoModalTitle.js'
import RepoModalBody from './RepoModalBody.js'
import RepoStats from '../RepoStats.js'
import './RepoModal.css'
import Helpers from '../../utils/Helpers.js'

class RepoModal extends React.Component {
  render() {
    const repo = this.props.repo
    return (
      <>
        <Modal show={this.props.show}
               onHide={this.props.closeClick}
               onEnter={this.props.loadData}
               dialogClassName="repo-modal"
               scrollable={true}
               centered={true}>
          <Modal.Header closeButton>
            <Modal.Title className="d-flex justify-content-center"
                         onClick={() => Helpers.openURL(repo.html_url)}>
              <RepoModalTitle repo={repo} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RepoModalBody data={this.props.tabData}
                           tabChanged={this.props.tabChanged}
                           isLoading={this.props.isLoading} />
          </Modal.Body>
          <Modal.Footer>
            <RepoStats repo={repo} />
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default RepoModal
