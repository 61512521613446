import whiteList from '../assets/white-list.json'

class ResponseFilter {
  constructor(items,route) {
    this.items = items
    this.route = route
    this.whiteList = this.makeWhiteList()
  }

  make() {
    if (this.route === "Home") {
      return this.home()
    } else {
      return this.items
    }
  }

  home() {
    return this.items.filter((i) => {
      return this.isItemInWhiteList(i)
    })
  }

  isItemInWhiteList(item) {
    return this.whiteList.includes(item.full_name)
  }

  makeWhiteList() {
    let final = []
    whiteList["items"].forEach((i) => {
      final = final.concat(i.repos)
    })
    return final
  }

}

export default ResponseFilter
