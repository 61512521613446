import React from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import LoadingBox from '../LoadingBox.js'
import IssueList from './IssueList.js'
import ContributorList from './ContributorList.js'
import EventList from './EventList.js'

class RepoModalBody extends React.Component {
  render() {
    return (
      <Tabs defaultActiveKey="issues"
            onSelect={this.props.tabChanged}
            className="modal-nav-tabs bg-white">
        <Tab eventKey="issues" title="Issues">
          <LoadingBox isOn={this.props.isLoading} />
          <IssueList data={this.props.data.issues} />
        </Tab>
        <Tab eventKey="contributors" title="Contributors">
          <LoadingBox isOn={this.props.isLoading} />
          <ContributorList data={this.props.data.contributors} />
        </Tab>
        <Tab eventKey="events" title="Events">
          <LoadingBox isOn={this.props.isLoading} />
          <EventList data={this.props.data.events} />
        </Tab>
      </Tabs>
    )
  }
}
export default RepoModalBody
