import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

class LoadingBox extends React.Component {
  render() {
    if (!this.props.isOn) {
      return null
    }
    return (
      <div className="w-100 text-center">
        <Spinner animation="border" size="sm" />
        <span className="ml-2">Loading...</span>
      </div>
    )
  }

}
export default LoadingBox
