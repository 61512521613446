import React from 'react'
import Card from 'react-bootstrap/Card'
import Media from 'react-bootstrap/Media'
import Image from 'react-bootstrap/Image'
import RepoStats from '../RepoStats.js'
import RepoCardBody from './RepoCardBody.js'

class RepoCard extends React.Component {
  render() {
    const d = this.props.data
    return (
      <Card className="base-card"
            border="light"
            onClick={this.props.onClick}>
        <Card.Body>
          <Media className="p-2">
            <Image src={d.owner.avatar_url}
                   className="avatar-img"
                   roundedCircle={true} />
            <Media.Body className="ml-3 d-flex justify-content-center">
              <RepoCardBody repo={d} />
            </Media.Body>
          </Media>
        </Card.Body>
        <Card.Footer className="bg-white border-0">
          <RepoStats repo={d} />
        </Card.Footer>
      </Card>
    )
  }
}

export default RepoCard
