import React from 'react'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'
import Octicon, {Repo} from '@githubprimer/octicons-react'

class RepoModalTitle extends React.Component {
  render() {
    const repo = this.props.repo
    const imgUrl = repo.owner ? repo.owner.avatar_url : ""
    return (
      <>
        <div>
          <Image src={imgUrl}
                 className="avatar-img mx-2"
                 roundedCircle={true} />
        </div>
        <div className="ml-3">
          <div>
            <Octicon icon={Repo}
                     verticalAlign="middle"
                     className="mr-2" />
            {repo.full_name}
            <Badge variant="dark"
                   className="ml-2">{repo.language}</Badge>
          </div>
          <div className="ml-4">
            <div className="smaller-text text-muted">{repo.description}</div>
          </div>
        </div>
      </>
    )
  }
}
export default RepoModalTitle
