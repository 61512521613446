import React from 'react'
import RepoModal from '../RepoModal/RepoModal.js'
import ApiCall from '../../utils/ApiCall.js'

class RepoContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      issues: [], contributors: [], events: [],
      isLoading: true, selectedKey: ''
    }
    this.loadData = this.loadData.bind(this)
    this.handleResponse = this.handleResponse.bind(this)
    this.tabChanged = this.tabChanged.bind(this)
    this.exitModal = this.exitModal.bind(this)
    this.apiCall = new ApiCall(this.handleResponse)
  }

  tabChanged(key) {
    if (this.state[key].length === 0) {
      this.loadData(key)
    }
  }

  exitModal() {
    this.setState({
      issues: [], contributors: [], events: [],
      isLoading: false, selectedKey: ''
    })
    this.props.closeClick()
  }

  loadData(key) {
    this.setState({ isLoading: true, selectedKey: key })
    this.apiCall.make(key, this.props.selectedRepo)
  }

  handleResponse(data) {
    const k = this.state.selectedKey
    this.setState({ [k]: data, isLoading: false })
  }

  tabData() {
    return {
      issues: this.state.issues,
      contributors: this.state.contributors,
      events: this.state.events
    }
  }

  render() {
    return (
      <RepoModal show={this.props.show}
                 repo={this.props.selectedRepo}
                 closeClick={this.exitModal}
                 loadData={() => this.loadData('issues')}
                 isLoading={this.state.isLoading}
                 tabData={this.tabData()}
                 tabChanged={this.tabChanged} />
    )
  }
}
export default RepoContainer
