import React from 'react'
import Container from 'react-bootstrap/Container'
import './App.css'
import Github from '../../utils/Github.js'
import Helpers from '../../utils/Helpers.js'
import SearchQuery from '../../utils/SearchQuery.js'
import ResponseFilter from '../../utils/ResponseFilter.js'
import TopNav from '../TopNav/TopNav.js'
import MainContent from '../MainContent/MainContent.js'
import RepoContainer from '../containers/RepoContainer.js'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.github = new Github()
    this.state = {
      repos: [],
      showRepo: false,
      selectedRepo: {},
      isLoading: true,
      route: 'Home'
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.repoClosed = this.repoClosed.bind(this)
  }

  componentDidMount() {
    this.loadRoute("Home")
  }

  handleSearch(data) {
    const items = new ResponseFilter(
      data.items,
      this.state.route
    ).make()
    this.setState({ repos: items, isLoading: false })
  }

  repoClosed() {
    this.setState({ showRepo: false })
  }

  routeClick(k) {
    Helpers.scrollToTop()
    this.loadRoute(k)
  }

  loadRoute(k) {
    this.setState({ isLoading: true, route: k })
    const q = new SearchQuery().repos(k)
    this.github.searchRepos(q, this.handleSearch)
  }

  repoClick(r) {
    this.setState({ showRepo: true, selectedRepo: r })
  }

  render() {
    return (
      <div className="app">
        <Container fluid={true}>
          <TopNav brandClick={k => this.routeClick(k)} />
          <MainContent repos={this.state.repos}
                       onClick={r => this.repoClick(r)}
                       featureClick={r => this.repoClick(r)}
                       isLoading={this.state.isLoading}
                       route={this.state.route} />
          <RepoContainer show={this.state.showRepo}
                         closeClick={this.repoClosed}
                         selectedRepo={this.state.selectedRepo} />
        </Container>
      </div>
    );
  }
}

export default App
