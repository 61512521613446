import topics from '../assets/topics.json'

class SearchQuery {
  repos(key) {
    return this.build(key)
  }

  build(key) {
    if (key === "Home") {
      return "pushed:>2019-04-21+stars:>1000"
    } else if (key === "Recent") {
      return "pushed:>2019-05-01+help-wanted-issues:>20"
    } else if (key === "Starters") {
      return "pushed:>2019-04-21+good-first-issues:>20"
    } else if (key === "Labs") {
      return "created:>2019-04-21"
    } else if (key === "Random") {
      return this.randomQuery()
    } else {
      return "Test+language:go"
    }
  }

  randomQuery() {
    const t = topics["items"]
    const num = Math.floor(Math.random() * t.length)
    return `topic:${t[num]}`
  }

}

export default SearchQuery
