import React from 'react'
import IssueCard from './IssueCard.js'
import Helpers from '../../utils/Helpers.js'

class IssueList extends React.Component {
  buildIssueCards() {
    const listItems = this.props.data.map((d) =>
      <IssueCard key={d.node_id}
                 data={d}
                 onClick={() => Helpers.openURL(d.html_url)} />
    )
    return listItems
  }

  render() {
    return (
      <div className="w-100 d-flex flex-wrap justify-content-center">
        {this.buildIssueCards()}
      </div>
    )
  }
}
export default IssueList
