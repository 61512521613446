import React from 'react'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import './Featured.css'
import featuredRepos from '../../../assets/featured.json'

class Featured extends React.Component {
  buildItems() {
    return featuredRepos["items"].map((r) =>
      <Carousel.Item key={r.name}
                     className="featured-item"
                     onClick={() => this.props.onClick(r)}>
        <div className="mt-4">
          <Image src={r.owner.avatar_url}
                 className="feature-img"
                 roundedCircle={true} />
          <h3>{r.feature_name}</h3>
        </div>
        <Carousel.Caption className="text-secondary">
          <h4>{r.description}</h4>
          <span  className="d-none d-md-inline">{r.feature_title}</span>
        </Carousel.Caption>
      </Carousel.Item>
    )
  }

  render() {
    return (
      <Carousel className="mx-4">
        {this.buildItems()}
      </Carousel>
    )
  }
}

export default Featured
