import React from 'react'
import BaseCard from '../../BaseCard/BaseCard.js'
import EventIcon from './EventIcon.js'
import EventBody from './EventBody.js'
import * as moment from 'moment'

class EventCard extends React.Component {
  render() {
    const p = this.props
    return (
      <BaseCard imgUrl={p.data.actor.avatar_url}
                user={p.data.actor.login}
                onClick={p.onClick}
                iconRender={() => (
                  <EventIcon type={p.data.type} />
                )}
                bodyRender={() => (
                  <EventBody event={p.data} />
                )}
                footRender={() => (
                  <>{moment(p.data.created_at).fromNow()}</>
                )} />
    )
  }
}
export default EventCard
