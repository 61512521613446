import React from 'react'
import leftNav from '../../assets/left-nav.json'

class PageHeader extends React.Component {
  render() {
    return (
      <div className="w-100 text-left m-2 pl-4">
        <h3><small className="text-muted">
          {leftNav[this.props.route].header}
        </small></h3>
      </div>
    )
  }
}
export default PageHeader
