import React from 'react'
import Featured from './Featured/Featured.js'

class HomeHeader extends React.Component {
  render() {
    return (
      <div className="w-100 my-4">
        <Featured onClick={(d) => this.props.featureClick(d)} />
      </div>
    )
  }
}
export default HomeHeader
