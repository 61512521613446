import React from 'react'
import Helpers from '../utils/Helpers.js'
import Octicon, {Star,RepoForked,IssueOpened} from '@githubprimer/octicons-react'

class RepoStats extends React.Component {
  render() {
    const r = this.props.repo
    const h = new Helpers()
    return (
      <div className="d-flex justify-content-center text-center w-100">
        <div className="p-2 flex-fill">
          <Octicon icon={Star}
                   className="mr-1" />
          {h.shortNum(r.stargazers_count)}
        </div>
        <div className="p-2 flex-fill">
          <Octicon icon={IssueOpened}
                   className="mr-1" />
          {h.shortNum(r.open_issues)}
        </div>
        <div className="p-2 flex-fill">
          <Octicon icon={RepoForked}
                   className="mr-1" />
          {h.shortNum(r.forks_count)}
        </div>
      </div>
    )
  }
}

export default RepoStats
