import React from 'react'

class EventBody extends React.Component {
  selectBody() {
    const e = this.props.event
    switch (e.type) {
      case 'WatchEvent':
        return `${e.payload.action} watching`
      case 'IssueCommentEvent':
        return `${e.payload.action} an issue comment`
      case 'ForkEvent':
        return 'Forked the repo'
      case 'IssuesEvent':
        return `${e.payload.action} an issue`
      case 'PushEvent':
        return `Pushed ${e.payload.commits.length} commits`
      case 'PullRequestEvent':
        return `${e.payload.action} a pull request`
      case 'PullRequestReviewCommentEvent':
        return `${e.payload.action} a PR review comment`
      case 'DeleteEvent':
        return `Deleted ${e.payload.ref_type} ${e.payload.ref}`
      case 'CreateEvent':
        return `Created ${e.payload.ref_type} ${e.payload.ref}`
      case 'CommitCommentEvent':
        return `Created a comment`
      case 'GollumEvent':
        return `Updated the wiki`
      default:
        return `${e.type}`
    }
  }

  render() {
    return (
      <span className="text-capitalize">
        {this.selectBody()}
      </span>
    )
  }
}
export default EventBody
