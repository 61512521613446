import React from 'react'
import Octicon, {Pulse,Eye,Comment,RepoForked,RepoPush,IssueOpened,GitPullRequest,Trashcan,Plus} from '@githubprimer/octicons-react'

class EventIcon extends React.Component {
  selectIcon() {
    switch(this.props.type) {
      case 'WatchEvent':
        return Eye
      case 'IssueCommentEvent':
        return Comment
      case 'ForkEvent':
        return RepoForked
      case 'IssuesEvent':
        return IssueOpened
      case 'PushEvent':
        return RepoPush
      case 'PullRequestEvent':
        return GitPullRequest
      case 'PullRequestReviewCommentEvent':
        return Comment
      case 'DeleteEvent':
        return Trashcan
      case 'CreateEvent':
        return Plus
      case 'CommitCommentEvent':
        return Comment
      default:
        return Pulse
    }
  }

  render() {
    return (
      <Octicon icon={this.selectIcon()}
               verticalAlign="middle"
               size="medium" />
    )
  }
}
export default EventIcon
