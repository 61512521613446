import React from 'react'
import HomeHeader from './HomeHeader.js'
import PageHeader from './PageHeader.js'

class Header extends React.Component {
  render() {
    let header
    if (this.props.route === "Home") {
      header = <HomeHeader featureClick={this.props.featureClick} />
    } else {
      header = <PageHeader route={this.props.route} />
    }
    return (
      <>{header}</>
    )
  }
}
export default Header
