import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import LeftNav from '../LeftNav/LeftNav.js'
import './TopNav.css'

class TopNav extends React.Component {
  render() {
    return (
      <Navbar fixed="top"
              bg="dark"
              variant="dark"
              expand="md"
              collapseOnSelect={true}>
        <Navbar.Brand onClick={() => this.props.brandClick("Home")}>
          <Image src="/gitted-logo.png"
                 className="logo-img"
                 roundedCircle={true} />
          <span className="ml-2">Gitted</span>
        </Navbar.Brand>
          <small className="text-muted">Find open source & commit</small>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <LeftNav linkClick={this.props.brandClick} />
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default TopNav
