import Octokit from '@octokit/rest'

class Github {
  constructor() {
    this.octokit = new Octokit()
  }

  rateLimit() {
    this.octokit.rateLimit.get(
    ).then(({data, headers, status}) => {
      console.log('Rate Limit')
      console.log(data)
      console.log('-------------')
    })
  }

  searchRepos(q, handleSearch) {
    this.octokit.search.repos({
      q: q,
      per_page: 100,
      page: 1,
      sort: 'stars'
    }).then(({data, headers, status}) => {
      handleSearch(data)
    })
  }

  issuesForRepo(owner, repo, handleResponse) {
    this.octokit.issues.listForRepo({
      owner: owner,
      repo: repo,
      per_page: 100
    }).then(({data, headers, status}) => {
      handleResponse(data)
    })
  }

  contributorsForRepo(owner, repo, handleResponse) {
    this.octokit.repos.listContributors({
      owner: owner,
      repo: repo,
      per_page: 100
    }).then(({data, headers, status}) => {
      handleResponse(data)
    })
  }

  eventsForRepo(owner, repo, handleResponse) {
    this.octokit.activity.listRepoEvents({
      owner: owner,
      repo: repo,
      per_page: 100
    }).then(({data, headers, status}) => {
      handleResponse(data)
    })
  }

}

export default Github
