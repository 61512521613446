import Github from './Github.js'

class ApiCall {
  constructor(handler) {
    this.handler = handler
    this.github = new Github()
  }

  make(key,repo) {
    const h = this.handler
    const u = repo.owner.login
    const n = repo.name
    const g = this.github

    switch (key) {
      case 'issues': g.issuesForRepo(u,n,h); break
      case 'contributors': g.contributorsForRepo(u,n,h); break
      case 'events': g.eventsForRepo(u,n,h); break
      default: break
    }
  }
}
export default ApiCall
