import React from 'react'
import Badge from 'react-bootstrap/Badge'

class LabelsList extends React.Component {
  buildLabels() {
    const labelItems = this.props.labels.map((l) =>
          <Badge variant="light"
                 className="m-1"
                 key={l.id}><small>{l.name}</small></Badge>
    )
    return labelItems
  }

  render() {
    return (
      <div>{this.buildLabels()}</div>
    )
  }
}
export default LabelsList
