import React from 'react'
import Card from 'react-bootstrap/Card'
import BaseCardBody from './BaseCardBody.js'
import BaseCardFooter from './BaseCardFooter.js'
import './BaseCard.css'

class BaseCard extends React.Component {
  render() {
    const p = this.props
    return (
      <Card className="base-card"
            border="light"
            onClick={p.onClick}>
         <Card.Body>
           <BaseCardBody imgUrl={p.imgUrl}
                         bodyRender={p.bodyRender}
                         iconRender={p.iconRender} />
         </Card.Body>
         <Card.Footer className="d-flex flex-wrap justify-content-center bg-white border-0">
           <BaseCardFooter user={p.user}
                           render={p.footRender} />
         </Card.Footer>
      </Card>
    )
  }
}

export default BaseCard
