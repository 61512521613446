import React from 'react'
import RepoCard from './RepoCard.js'

class RepoList extends React.Component {
  buildCards() {
    const listItems = this.props.repos.map((d) =>
      <RepoCard key={d.node_id}
                data={d}
                onClick={() => this.props.onClick(d)} />
    )
    return listItems
  }

  render() {
    return (
      <>{this.buildCards()}</>
    )
  }
}
export default RepoList
