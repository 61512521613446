import React from 'react'
import Nav from 'react-bootstrap/Nav'
import NavLinks from './NavLinks.js'
import {Home,Zap,Beaker,MortarBoard,Flame} from '@githubprimer/octicons-react'
import './LeftNav.css'

class LeftNav extends React.Component {
  constructor(props) {
    super(props)
    this.linkMap = [
      {name:"Home", icon: Home},
      {name:"Recent", icon: Flame},
      {name:"Starters", icon: MortarBoard},
      {name:"Labs", icon: Beaker},
      {name:"Random", icon: Zap}
    ]
  }

  render() {
    return (
      <Nav className="left-nav flex-row bg-dark justify-content-center"
           onSelect={k => this.props.linkClick(k)}>
           <NavLinks linkMap={this.linkMap} />
      </Nav>
    )
  }
}

export default LeftNav
