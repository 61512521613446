import React from 'react'

class BaseCardFooter extends React.Component {
  render() {
    const p = this.props
    return (
      <>
        <div className="flex-fill">
          <small className="ml-2">{p.user}</small>
        </div>
        <small className="ml-2 text-muted">
          {p.render()}
        </small>
      </>
    )
  }
}
export default BaseCardFooter
